<template>
  <div class="bg">
    <!-- XL -->
    <div class="hidden-lg-and-down">
      <v-img
        src="@/assets/etusivu-01.webp"
        lazy-src="@/assets/etusivu-01.webp"
        contain
        :aspect-ratio="2.182"
      >
        <v-container class="mt-12">
          <!-- <eulogos :w="120"></eulogos> -->
          <v-row class="ma-6">
            <v-col cols="12">
              <p class="title-orange">{{ translations.title[locale] }}</p>
            </v-col>
          </v-row>
          <v-row class="ma-6">
            <v-col cols="7">
              <p
                v-for="(t, ti) in translations.text"
                :key="ti"
                class="text-normal"
              >
                <span v-html="t[locale]"></span>
              </p>
            </v-col>
          </v-row>
          <v-row class="ma-6">
            <v-col>
              <svgbutton
                class="svgorange"
                :bgwhite="false"
                :text_x="locale == 'fi' ? 47 : 85"
                :buttontext="translations.button_1"
                :buttonwidth="300"
                :key="fReload"
                @click.native="btnaction('references')"
              ></svgbutton>
            </v-col>
          </v-row>
        </v-container>
      </v-img>

      <div class="middle-section">
        <v-container>
          <v-row class="lifecycle-title ma-12" justify="center">
            <!-- <p class="title-orange">{{ translations.servicesTitle[locale] }}</p> -->
          </v-row>
          <v-row class="lifecycle" justify="center" no-gutters>
            <v-col cols="12" sm="4" class="pa-3">
              <v-img
                class="pictures-xl"
                src="@/assets/idean_jalostus_tuotteeksi-01.png"
                lazy-src="@/assets/idean_jalostus_tuotteeksi-01.png"
                contain
              ></v-img>
              <h2>{{ translations.ideaTitle[locale] }}</h2>
              <p class="services-text">{{ translations.ideaText[locale] }}</p>
            </v-col>
            <v-col cols="12" sm="4" class="pa-3">
              <v-img
                class="pictures-xl"
                src="@/assets/kohti_markkinoita-01.png"
                lazy-src="@/assets/kohti_markkinoita-01.png"
                contain
              ></v-img>
              <h2>{{ translations.towardsMarketingTitle[locale] }}</h2>
              <p>{{ translations.towardsMarketingText[locale] }}</p>
            </v-col>
            <v-col cols="12" sm="4" class="pa-3">
              <v-img
                class="pictures-xl"
                src="@/assets/kaupallistaminen.png"
                lazy-src="@/assets/kaupallistaminen.png"
                contain
              ></v-img>
              <h2>{{ translations.commercializationTitle[locale] }}</h2>
              <p>{{ translations.commercializationText[locale] }}</p>
            </v-col>
          </v-row>
          <v-row class="justify-space-around">
            <v-spacer></v-spacer>
            <v-col class="ma-12">
              <svgbutton
                class="btn-middle"
                :bgwhite="true"
                :text_x="locale == 'fi' ? 55 : 80"
                :buttontext="translations.button_2"
                :buttonwidth="400"
                @click.native="btnaction('themes')"
              ></svgbutton>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
          <v-row>
            <v-card
              class="mx-auto mt-12"
              :color="$blue"
              tile
              flat
              max-width="1200"
            >
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <v-sheet
                      class="mx-auto"
                      width="400"
                      height="400"
                      color="rgba(0, 0, 0, 0.0)"
                    >
                      <v-img
                        class="map-card-xl"
                        src="@/assets/kartta-01.png"
                        lazy-src="@/assets/kartta-01.png"
                        height="800"
                        contain
                      ></v-img>
                    </v-sheet>
                  </v-col>
                  <v-col class="my-6" cols="6">
                    <v-sheet
                      class="my-2"
                      min-width="200"
                      height="100%"
                      color="rgba(0, 0, 0, 0.0)"
                    >
                      <p class="title-orange">
                        {{ translations.developmentEnvironmentsTitle[locale] }}
                      </p>
                      <p
                        v-for="(
                          t, di
                        ) in translations.developmentEnvironmentsText"
                        :key="di"
                        class="text-normal"
                      >
                        {{ t[locale] }}
                      </p>
                      <svgbutton
                        class="mt-6"
                        :bgwhite="false"
                        :text_x="locale == 'fi' ? 60 : 80"
                        :buttontext="translations.button_3"
                        :buttonwidth="410"
                        @click.native="btnaction('environments')"
                      ></svgbutton>
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-row>
          <v-row class="logos-xl">
            <v-col v-for="(l, i) in logos" :key="`logo_${i}`">
              <v-img
                :src="l.img"
                max-height="80"
                contain=""
                @click="l.url != null ? openTab(l.url) : false"
              ></v-img>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <!-- LG -->
    <div class="hidden-xl-only hidden-md-and-down">
      <!-- <div class="upper-section-lg"></div> -->
      <v-img
        src="@/assets/etusivu-01.webp"
        lazy-src="@/assets/etusivu-01.webp"
        contain
        :aspect-ratio="1.7"
      >
        <!-- <eulogos :w="110"></eulogos> -->
        <v-container class="mt-6">
          <v-row class="ma-6">
            <v-col cols="12">
              <p class="title-orange">{{ translations.title[locale] }}</p>
            </v-col>
          </v-row>
          <v-row class="mx-6 my-n8">
            <v-col cols="7">
              <p
                v-for="(t, ti) in translations.text"
                :key="ti"
                class="text-normal-lg"
              >
                <span v-html="t[locale]"></span>
              </p>
            </v-col>
          </v-row>
          <v-row class="lg-button ma-6 mb-12">
            <v-col>
              <svgbutton
                class="svgorange"
                :bgwhite="false"
                :text_x="locale == 'fi' ? 47 : 85"
                :buttontext="translations.button_1"
                :buttonwidth="300"
                :key="fReload"
                @click.native="btnaction('references')"
              ></svgbutton>
            </v-col>
          </v-row>
        </v-container>
        <!-- <div :style="{background: 'white', width: 100+'%', height: 100+'px', position: 'absolute', top: 650 + 'px'}">

          </div> -->
      </v-img>
      <div class="middle-section-lg">
        <v-container>
          <v-row class="lifecycle-title ma-12" justify="center">
            <!-- <p class="title-orange">{{ translations.servicesTitle[locale] }}</p> -->
          </v-row>
          <v-row class="lifecycle" justify="center" no-gutters>
            <v-col cols="12" sm="4" class="pa-3">
              <v-img
                class="pictures"
                src="@/assets/idean_jalostus_tuotteeksi-01.png"
                contain
              ></v-img>
              <h2>{{ translations.ideaTitle[locale] }}</h2>
              <p class="services-text">{{ translations.ideaText[locale] }}</p>
            </v-col>
            <v-col cols="12" sm="4" class="pa-3">
              <v-img
                class="pictures"
                src="@/assets/kohti_markkinoita-01.png"
                contain
              ></v-img>
              <h2>{{ translations.towardsMarketingTitle[locale] }}</h2>
              <p>{{ translations.towardsMarketingText[locale] }}</p>
            </v-col>
            <v-col cols="12" sm="4" class="pa-3">
              <v-img
                class="pictures"
                src="@/assets/kaupallistaminen.png"
                contain
              ></v-img>
              <h2>{{ translations.commercializationTitle[locale] }}</h2>
              <p>{{ translations.commercializationText[locale] }}</p>
            </v-col>
          </v-row>
          <v-row class="justify-space-around">
            <v-spacer></v-spacer>
            <v-col class="ma-12">
              <svgbutton
                class="btn-middle"
                :bgwhite="true"
                :text_x="locale == 'fi' ? 55 : 80"
                :buttontext="translations.button_2"
                :buttonwidth="400"
                @click.native="btnaction('themes')"
              ></svgbutton>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
          <v-row>
            <v-card
              class="mx-auto mt-12"
              :color="$blue"
              tile
              flat
              max-width="1200"
            >
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <v-sheet
                      class="mx-auto"
                      width="400"
                      height="400"
                      color="rgba(0, 0, 0, 0.0)"
                    >
                      <v-img
                        class="map-card"
                        src="@/assets/kartta-01.png"
                        lazy-src="@/assets/kartta-01.png"
                        height="600"
                        width="500"
                      ></v-img>
                    </v-sheet>
                  </v-col>
                  <v-col class="my-6" cols="6">
                    <v-sheet
                      class="my-2"
                      min-width="200"
                      height="100%"
                      color="rgba(0, 0, 0, 0.0)"
                    >
                      <p class="title-orange">
                        {{ translations.developmentEnvironmentsTitle[locale] }}
                      </p>
                      <p
                        v-for="(
                          t, di
                        ) in translations.developmentEnvironmentsText"
                        :key="di"
                        class="text-normal"
                      >
                        {{ t[locale] }}
                      </p>
                      <svgbutton
                        class="mt-6"
                        :bgwhite="false"
                        :text_x="locale == 'fi' ? 60 : 80"
                        :buttontext="translations.button_3"
                        :buttonwidth="410"
                        @click.native="btnaction('environments')"
                      ></svgbutton>
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-row>
          <v-row class="logos-xl">
            <v-col v-for="(l, i) in logos" :key="`logo_${i}`">
              <v-img
                :src="l.img"
                max-height="60"
                max-width="210"
                @click="l.url != null ? openTab(l.url) : false"
                contain
              ></v-img>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <!-- MD -->
    <div class="hidden-lg-and-up hidden-sm-and-down">
      <!-- <div class="upper-section-md"></div> -->
      <v-img
        src="@/assets/etusivu-01.webp"
        lazy-src="@/assets/etusivu-01.webp"
        contain
      >
        <!-- <eulogos :w="90"></eulogos> -->
        <v-container class="container-md">
          <v-row class="ml-2 my-n2">
            <v-col cols="12">
              <p class="title-orange-md">{{ translations.title[locale] }}</p>
            </v-col>
          </v-row>
          <v-row class="ml-2 my-n2">
            <v-col cols="7">
              <p
                v-for="(t, ti) in translations.text"
                :key="ti"
                class="text-normal-md"
              >
                <span v-html="t[locale]"></span>
              </p>
            </v-col>
          </v-row>
          <v-row class="ml-2 my-n6 lg-button">
            <v-col>
              <svgbutton
                class="svgorange"
                :bgwhite="false"
                :text_x="locale == 'fi' ? 42 : 66"
                :buttontext="translations.button_1"
                :buttonwidth="240"
                :btnheight="50"
                :btnfont="14"
                :key="fReload"
                @click.native="btnaction('references')"
              ></svgbutton>
            </v-col>
          </v-row>
        </v-container>
      </v-img>
      <div class="middle-section-md">
        <v-container>
          <v-row class="lifecycle-title ma-12" justify="center">
            <!-- <p class="title-orange">{{ translations.servicesTitle[locale] }}</p> -->
          </v-row>
          <v-row class="lifecycle" justify="center" no-gutters>
            <v-col cols="12" sm="4" class="pa-3">
              <v-img
                class="pictures-md"
                src="@/assets/idean_jalostus_tuotteeksi-01.png"
                contain
              ></v-img>
              <h2>{{ translations.ideaTitle[locale] }}</h2>
              <p class="services-text">{{ translations.ideaText[locale] }}</p>
            </v-col>
            <v-col cols="12" sm="4" class="pa-3">
              <v-img
                class="pictures-md"
                src="@/assets/kohti_markkinoita-01.png"
                contain
              ></v-img>
              <h2>{{ translations.towardsMarketingTitle[locale] }}</h2>
              <p>{{ translations.towardsMarketingText[locale] }}</p>
            </v-col>
            <v-col cols="12" sm="4" class="pa-3">
              <v-img
                class="pictures-md"
                src="@/assets/kaupallistaminen.png"
                contain
              ></v-img>
              <h2>{{ translations.commercializationTitle[locale] }}</h2>
              <p>{{ translations.commercializationText[locale] }}</p>
            </v-col>
          </v-row>
          <v-row class="justify-space-around">
            <v-spacer></v-spacer>
            <v-col class="ma-12">
              <svgbutton
                class="btn-middle"
                :bgwhite="true"
                :text_x="locale == 'fi' ? 90 : 80"
                :buttontext="translations.button_2"
                :buttonwidth="400"
                :btnheight="50"
                :btnfont="14"
                @click.native="btnaction('themes')"
              ></svgbutton>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
          <v-row>
            <v-card
              class="mx-auto mt-12"
              :color="$blue"
              tile
              flat
              max-width="1000"
            >
              <v-container>
                <v-row>
                  <v-col cols="5">
                    <v-sheet
                      class="mx-auto"
                      width="235"
                      height="400"
                      color="rgba(0, 0, 0, 0.0)"
                    >
                      <v-img
                        class="map-card"
                        src="@/assets/kartta-01.png"
                        lazy-src="@/assets/kartta-01.png"
                        height="550"
                        width="450"
                      ></v-img>
                    </v-sheet>
                  </v-col>
                  <v-col cols="7">
                    <v-sheet
                      class="my-2"
                      min-width="200"
                      height="100%"
                      color="rgba(0, 0, 0, 0.0)"
                    >
                      <p class="title-orange-md">
                        {{ translations.developmentEnvironmentsTitle[locale] }}
                      </p>

                      <p
                        v-for="(
                          t, di
                        ) in translations.developmentEnvironmentsText"
                        :key="di"
                        class="text-normal-md"
                      >
                        {{ t[locale] }}
                      </p>
                      <svgbutton
                        class="mt-6"
                        :bgwhite="false"
                        :text_x="locale == 'fi' ? 70 : 60"
                        :buttontext="translations.button_3"
                        :buttonwidth="360"
                        :btnheight="50"
                        :btnfont="14"
                        @click.native="btnaction('environments')"
                      ></svgbutton>
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-row>
          <v-row class="logos-xl">
            <v-col v-for="(l, i) in logos" :key="`logo_${i}`">
              <v-img
                :src="l.img"
                max-height="55"
                max-width="150"
                @click="l.url != null ? openTab(l.url) : false"
                contain
              ></v-img>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <!-- SM -->
    <div class="hidden-md-and-up hidden-xs-only">
      <div class="upper-section-sm"></div>
      <div class="img-container-sm">
        <v-container>
          <!-- <eulogos :w="90"></eulogos> -->
          <v-row class="ml-2 my-n2">
            <v-col cols="12">
              <p class="title-orange-sm">{{ translations.title[locale] }}</p>
            </v-col>
          </v-row>
          <v-row class="ml-2 my-n2">
            <v-col cols="10">
              <p
                v-for="(t, ti) in translations.text"
                :key="ti"
                class="text-normal-sm"
              >
                <span v-html="t[locale]"></span>
              </p>
            </v-col>
          </v-row>
          <v-row class="ml-2 my-n2">
            <v-col>
              <svgbutton
                class="svgorange"
                :bgwhite="false"
                :text_x="locale == 'fi' ? 47 : 85"
                :buttontext="translations.button_1"
                :buttonwidth="300"
                :key="fReload"
                @click.native="btnaction('references')"
              ></svgbutton>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-img src="@/assets/etusivu-02.webp" lazy-src="@/assets/etusivu-02.webp">
      </v-img>
      <div class="middle-section-sm">
        <v-container>
          <v-row class="lifecycle-title ma-12" justify="center">
            <!-- <p class="title-orange">{{ translations.servicesTitle[locale] }}</p> -->
          </v-row>
          <v-row class="lifecycle-sm" justify="center" no-gutters>
            <v-col cols="12" sm="4" class="pa-3">
              <v-img
                class="pictures"
                src="@/assets/idean_jalostus_tuotteeksi-01.png"
                contain
              ></v-img>
              <h2 class="services-title">
                {{ translations.ideaTitle[locale] }}
              </h2>
              <p class="services-text">{{ translations.ideaText[locale] }}</p>
            </v-col>
            <v-col cols="12" sm="4" class="pa-3">
              <v-img
                class="pictures"
                src="@/assets/kohti_markkinoita-01.png"
                contain
              ></v-img>
              <h2 class="services-title">
                {{ translations.towardsMarketingTitle[locale] }}
              </h2>
              <p class="services-text">
                {{ translations.towardsMarketingText[locale] }}
              </p>
            </v-col>
            <v-col cols="12" sm="4" class="pa-3">
              <v-img
                class="pictures"
                src="@/assets/kaupallistaminen.png"
                contain
              ></v-img>
              <h2 class="services-title">
                {{ translations.commercializationTitle[locale] }}
              </h2>
              <p class="services-text">
                {{ translations.commercializationText[locale] }}
              </p>
            </v-col>
          </v-row>
          <v-row class="justify-space-around">
            <v-spacer></v-spacer>
            <v-col class="ma-12">
              <svgbutton
                class="btn-middle"
                :bgwhite="true"
                :text_x="locale == 'fi' ? 55 : 80"
                :buttontext="translations.button_2"
                :buttonwidth="400"
                @click.native="btnaction('themes')"
              ></svgbutton>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
          <v-row>
            <v-card
              class="mx-auto"
              :color="$blue"
              tile
              flat
              :style="{ width: 72 + 'vw' }"
            >
              <v-container>
                <v-row>
                  <v-col>
                    <div class="map-bg-sm" :style="{ height: 62 + 'vw' }">
                      <v-img
                        class="map-card-sm ml-n6"
                        src="@/assets/kartta-01.png"
                        lazy-src="@/assets/kartta-01.png"
                      ></v-img>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-sheet
                      class="my-4"
                      min-width="200"
                      height="100%"
                      color="rgba(0, 0, 0, 0.0)"
                    >
                      <p class="title-orange-md mx-2">
                        {{ translations.developmentEnvironmentsTitle[locale] }}
                      </p>
                      <p
                        v-for="(
                          t, di
                        ) in translations.developmentEnvironmentsText"
                        :key="di"
                        class="text-normal-sm mx-2"
                      >
                        {{ t[locale] }}
                      </p>
                      <svgbutton
                        class="mt-6 mx-auto"
                        :bgwhite="false"
                        :text_x="locale == 'fi' ? 55 : 55"
                        :buttontext="translations.button_3"
                        :buttonwidth="400"
                        @click.native="btnaction('environments')"
                      ></svgbutton>
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-row>
          <v-row class="logos-xl">
            <v-col v-for="(l, i) in logos" :key="`logo_${i}`">
              <v-img
                :src="l.img"
                max-height="70"
                max-width="200"
                @click="l.url != null ? openTab(l.url) : false"
                contain
              ></v-img>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <!-- XS -->
    <div class="hidden-sm-and-up">
      <div class="upper-section-xs"></div>
      <div class="img-container-xs">
        <v-container>
          <!-- <eulogos :w="70"></eulogos> -->
          <v-row class="ml-n3 my-n6">
            <v-col cols="12">
              <p class="title-orange-xs">{{ translations.title[locale] }}</p>
            </v-col>
          </v-row>
          <v-row class="ml-n3 my-n6">
            <v-col cols="9">
              <p
                v-for="(t, ti) in translations.text"
                :key="ti"
                class="text-normal-xs"
              >
                <span v-html="t[locale]"></span>
              </p>
            </v-col>
          </v-row>
          <v-row class="ml-n3 my-n4">
            <v-col>
              <svgbutton
                class="svgorange"
                :bgwhite="false"
                :text_x="locale == 'fi' ? 25 : 45"
                :buttontext="translations.button_1"
                :buttonwidth="180"
                :btnheight="40"
                :btnfont="12"
                :key="fReload"
                @click.native="btnaction('references')"
              ></svgbutton>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-img src="@/assets/etusivu-02.webp" lazy-src="@/assets/etusivu-02.webp">
      </v-img>
      <div class="middle-section-xs mt-n1">
        <v-container class="mt-n12">
          <v-row class="lifecycle-title ma-12" justify="center">
            <!-- <p class="title-orange">{{ translations.servicesTitle[locale] }}</p> -->
          </v-row>
          <v-row class="lifecycle-xs" justify="center" no-gutters>
            <v-col cols="12" sm="4" class="pa-5">
              <v-img
                class="pictures-xs"
                src="@/assets/idean_jalostus_tuotteeksi-01.png"
                contain
              ></v-img>
              <h2 class="services-title-xs">
                {{ translations.ideaTitle[locale] }}
              </h2>
              <p class="services-text-xs">
                {{ translations.ideaText[locale] }}
              </p>
            </v-col>
            <v-col cols="12" sm="4" class="pa-5">
              <v-img
                class="pictures-xs"
                src="@/assets/kohti_markkinoita-01.png"
                contain
              ></v-img>
              <h2 class="services-title-xs">
                {{ translations.towardsMarketingTitle[locale] }}
              </h2>
              <p class="services-text-xs">
                {{ translations.towardsMarketingText[locale] }}
              </p>
            </v-col>
            <v-col cols="12" sm="4" class="pa-5">
              <v-img
                class="pictures-xs"
                src="@/assets/kaupallistaminen.png"
                contain
              ></v-img>
              <h2 class="services-title-xs">
                {{ translations.commercializationTitle[locale] }}
              </h2>
              <p class="services-text-xs">
                {{ translations.commercializationText[locale] }}
              </p>
            </v-col>
          </v-row>
          <v-row class="justify-space-around">
            <v-spacer></v-spacer>
            <v-col class="my-12">
              <svgbutton
                class="btn-middle"
                :bgwhite="true"
                :text_x="locale == 'fi' ? 32 : 80"
                :buttontext="translations.button_2"
                :buttonwidth="250"
                :btnheight="40"
                :btnfont="12"
                @click.native="btnaction('themes')"
              ></svgbutton>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
          <v-row>
            <v-card
              class="mx-auto"
              :color="$blue"
              tile
              flat
              :style="{ width: 82 + 'vw' }"
            >
              <v-container>
                <v-row>
                  <v-col>
                    <div class="map-bg-xs" :style="{ height: 82 + 'vw' }">
                      <v-img
                        class="map-card-xs ml-n6"
                        src="@/assets/kartta-01.png"
                        lazy-src="@/assets/kartta-01.png"
                      ></v-img>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-sheet
                      class="my-4"
                      min-width="200"
                      height="100%"
                      color="rgba(0, 0, 0, 0.0)"
                    >
                      <p class="title-orange-xs mx-2">
                        {{ translations.developmentEnvironmentsTitle[locale] }}
                      </p>
                      <p
                        v-for="(
                          t, di
                        ) in translations.developmentEnvironmentsText"
                        :key="di"
                        class="text-normal-xs mx-2"
                      >
                        {{ t[locale] }}
                      </p>
                      <svgbutton
                        class="mt-6 mx-auto"
                        :bgwhite="false"
                        :text_x="locale == 'fi' ? 25 : 15"
                        :buttontext="translations.button_3"
                        :buttonwidth="215"
                        :btnheight="40"
                        :btnfont="10"
                        @click.native="btnaction('environments')"
                      ></svgbutton>
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-row>
          <v-row class="logos-xs">
            <v-col v-for="(l, i) in logos" :key="`logo_${i}`">
              <v-img
                :src="l.img"
                max-height="60"
                max-width="150"
                @click="l.url != null ? openTab(l.url) : false"
                contain
              ></v-img>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<style scoped>
.bg {
  background: #002135;
}
.upper-section-lg {
  width: 100%;
  height: 100px;
  background: #002135;
}
.upper-section-md {
  background: #002135;
  height: 100px;
}
.upper-section-sm {
  background: #002135;
  height: 300px;
}
.upper-section-xs {
  background: #002135;
  height: 340px;
}
.title-orange {
  color: #f7941e;
  font-size: 32px;
  font-weight: 600;
}
.title-orange-md {
  color: #f7941e;
  font-size: 26px;
  font-weight: 600;
}
.title-orange-sm {
  color: #f7941e;
  font-size: 28px;
  font-weight: 600;
}
.title-orange-xs {
  color: #f7941e;
  font-size: 18px;
  font-weight: 600;
}
.text-normal {
  color: white;
  font-size: 18px;
}
.text-normal-lg {
  color: white;
  font-size: 18px;
}
.text-normal-md {
  color: white;
  font-size: 14px;
}
.text-normal-sm {
  color: white;
  font-size: 16px;
}
.text-normal-xs {
  color: white;
  font-size: 13px;
}
.middle-section {
  background: white;
  width: 100%;
  height: 1700px;
}
.middle-section-xl {
  background: white;
  width: 100%;
  height: 1700px;
}
.middle-section-lg {
  background: white;
  margin-top: -130px;
  width: 100%;
  height: 1700px;
}
.middle-section-md {
  background: white;
  margin-top: -70px;
  width: 100%;
  height: 1600px;
}
.middle-section-sm {
  background: white;
  width: 100%;
  height: 2450px;
}
.middle-section-xs {
  background: white;
  width: 100%;
  height: 2600px;
}
.pictures-xl {
  height: 200px;
  text-align: center;
  margin-top: -125px;
}
.pictures-md {
  height: 150px;
  text-align: center;
}
.pictures {
  height: 200px;
  text-align: center;
  margin-top: -50px;
}
.pictures-xs {
  height: 120px;
  text-align: center;
  margin-top: -30px;
}
.btn-middle {
  margin-left: auto;
  margin-right: auto;
}
.lifecycle {
  text-align: center;
}
.lifecycle-sm {
  text-align: center;
}
.lifecycle-xs {
  text-align: center;
}
.lifecycle-title {
  position: relative;
  margin-top: 120px;
  text-align: center;
}
.services-title {
  font-size: 22px;
}
.services-text {
  font-size: 16px;
}
.logos {
  position: relative;
  margin-top: -100px;
}
.logos-xl {
  position: relative;
  margin-top: 210px;
}
.logos-xs {
  position: relative;
  margin-top: 210px;
}
.map-card {
  position: absolute;
  margin-left: -120px;
  margin-top: -80px;
}
.map-card-xl {
  position: absolute;
  margin-left: -250px;
  margin-top: -120px;
}
.map-card-sm {
  position: absolute;
  width: 550px;
  margin-top: -100px;
}
.img-container-sm {
  position: absolute;
  margin-top: -290px;
  z-index: 2;
}
.img-container-xs {
  position: absolute;
  margin-top: -360px;
  z-index: 2;
}
/* .img-snowman-lg {
  background: linear-gradient(
    to bottom,
    #002135 0%,
    #002135 50%,
    #000000 50%,
    white 50%,
    white 100%
  );
} */
.services-title-xs {
  font-size: 20px;
}
.services-text-xs {
  font-size: 13px;
}
.lg-button {
  margin-bottom: 88px !important;
}
</style>

<script>
import svgbutton from "../../components/svgbutton";
// import eulogos from "../../components/EU_Logos";
import { PORTAL_INDEX } from "../../constants/translations";

export default {
  name: "Portal",
  components: {
    svgbutton,
    // eulogos
  },
  data() {
    return {
      translations: PORTAL_INDEX,
      fReload: 0,
      logos_eu: {
        fi: [
          {
            img: require("@/assets/EU_EAKR_FI_vertical_20mm_rgb.png"),
            url: null,
          },
          {
            img: require("@/assets/VipuvoimaaEU_2014_2020_rgb.png"),
            url: null,
          },
        ],
        en: [
          {
            img: require("@/assets/Logo_EU_EAKR_EN.png"),
            url: null,
          },
          {
            img: require("@/assets/Logo_LeverageEU_2014_2020.png"),
            url: null,
          },
        ],
      },
      logos: [
        {
          img: require("@/assets/ASR-logo.png"),
          url: "https://arcticsmartness.eu/arctic-smartness-rdi/",
        },
        {
          img: require("@/assets/Geological_Survey_of_Finland_GTK-300x91.png"),
          url: "https://www.gtk.fi/",
        },
        {
          img: require("@/assets/Lapland_University_of_Applied_Sciences-300x79.png"),
          url: "https://www.lapinamk.fi/fi",
        },
        {
          img: require("@/assets/Natural_Resources_Institute_Finland_Luke-300x263.png"),
          url: "https://www.luke.fi/",
        },
        {
          img: require("@/assets/University_of_Lapland-300x70.png"),
          url: "https://www.ulapland.fi/FI",
        },
      ],
    };
  },
  methods: {
    btnaction(route) {
      this.$router.push({ name: route });
    },
    openTab(url) {
      window.open(url, "_blank");
    },
  },
  computed: {
    locale() {
      return this.$store.state.locale;
    },
  },
  watch: {
    locale() {
      this.fReload += 1;
    },
  },
};
</script>
