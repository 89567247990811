<template>
  <div :style="{ width: (width + 20) + 'px'}">
    <template v-if="bgWhite == true">
      <svg
        class="svgorange"
        :width="width + 20"
        :height="height + 20"
      >
        <g>
          <rect
            :x="12"
            :y="12"
            :width="width"
            :height="height"
            class="orangerect"
          />
          <rect
            :x="4"
            :y="4"
            :width="width"
            :height="height"
            class="blackrect"
          />
          <text
            :x="txt_x"
            y="50%"
            :font-size="font"
            font-weight="bold"
            fill="black"
          >{{ text[locale] }}</text>Sorry, your browser does not support inline SVG.
        </g>
      </svg>
    </template>
    <template v-else>
      <svg
        class="svgorange"
        :width="width + 20"
        :height="height + 20"
      >
        <g>
          <rect
            :x="12"
            :y="12"
            :width="width"
            :height="height"
            class="whiterect"
          />
          <rect
            :x="4"
            :y="4"
            :width="width"
            :height="height"
            class="orangerect"
          />
          <text
            :x="txt_x"
            y="50%"
            :font-size="font"
            font-weight="bold"
            :fill="$orange"
          >{{ text[locale] }}</text>Sorry, your browser does not support inline SVG.
        </g>
      </svg>
    </template>
  </div>
</template>


<style scoped>
.orangerect {
  fill: none;
  stroke-width: 3;
  stroke: #f7941e;
}
.whiterect {
  fill: none;
  stroke-width: 3;
  stroke: white;
}
.blackrect {
  fill: none;
  stroke-width: 3;
  stroke: black;
}
.svgorange {
  cursor: pointer;
  
}
</style>

<script>
export default {
  data() {
    return {
      text: this.buttontext,
      width: this.buttonwidth,
      height: this.btnheight != null ? this.btnheight : 60,
      font: this.btnfont != null ? this.btnfont : 18,
      bgWhite: this.bgwhite,
      componentName: this.btnaction,
      txt_x: this.text_x
    };
  },
  props: {
    buttontext: Object,
    buttonwidth: Number,
    btnfont: {
      type: Number,
      required: false
    },
    btnheight: {
      type: Number,
      required: false
    },
    text_x: {
      type: Number,
      required: true
    },
    bgwhite: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    locale() {
      return this.$store.state.locale;
    }
  }
};
</script>
